/* eslint-disable no-undef */
<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png" fit="cover" /> -->
    <h1>Lenovo Auto Login</h1>
    <van-form @submit="onSubmit">
      <van-field
        v-model="phoneNumber"
        name="手机号"
        label="手机号"
        placeholder="手机号"
        :rules="[{ required: true, message: '请填写手机号' }]"
      />
      <van-field
        v-model="captcha"
        center
        clearable
        label="短信验证码"
        placeholder="验证码(密码验证码二选一)"
      >
        <template #button>
          <van-button
            native-type="button"
            size="small"
            type="primary"
            @click="handleCaptcha"
            >发送验证码</van-button
          >
        </template>
      </van-field>
      <van-field
        v-model="password"
        clearable
        name="密码"
        label="密码"
        placeholder="密码(密码验证码二选一)"
        type="password"
      />
      <div style="margin: 16px;">
        <van-button
          round
          block
          type="primary"
          native-type="button"
          @click="loginWithPasswordHandler"
          style="margin-bottom:10px;"
        >
          密码登录
        </van-button>
        <van-button round block type="info" native-type="submit">
          验证码登录
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import api from "./utils/api";
export default {
  name: "App",
  data() {
    return {
      phoneNumber: "",
      captcha: "",
      password: "",
    };
  },
  methods: {
    onSubmit() {
      api
        .addAccount({
          phoneNumber: this.phoneNumber,
          captcha: this.captcha,
        })
        .then(() => {
          // eslint-disable-next-line no-undef
          vant.Toast("登录成功");
        })
        .catch((err) => {
          // eslint-disable-next-line no-undef
          vant.Toast("登陆失败\n" + err.msg);
          console.error(err);
        });
    },
    loginWithPasswordHandler() {
      api
        .addAccountWithPassword({
          phoneNumber: this.phoneNumber,
          password: api.encode(this.password),
        })
        .then(() => {
          // eslint-disable-next-line no-undef
          vant.Toast("登录成功");
        })
        .catch((err) => {
          // eslint-disable-next-line no-undef
          vant.Toast("登录失败\n" + err.msg);
          console.error(err);
        });
    },
    handleCaptcha() {
      if (this.phoneNumber !== "" && !isNaN(this.phoneNumber)) {
        api
          .sendCaptcha({
            phoneNumber: this.phoneNumber,
          })
          .then(() => {
            // eslint-disable-next-line no-undef
            vant.Toast("发送成功");
          })
          .catch((err) => {
            // eslint-disable-next-line no-undef
            vant.Toast("发送失败:" + err.msg);
            console.error(err);
          });
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
