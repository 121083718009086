/* eslint-disable no-undef */

// url基础地址
// var baseUrl = "http://localhost:1033/v1/api";
var baseUrl = 'http://114.116.250.233:3324/v1/api';
axios.defaults.baseURL = baseUrl;
axios.interceptors.request.use(
	(config) => {
		return config;
	},
	(error) => {
		console.log(error);
		return Promise.reject();
	}
);

axios.interceptors.response.use(
	(response) => {
		if (response.status === 200 && response.data.code === 200) {
			return response.data.data;
		} else {
			return Promise.reject(response.data);
		}
	},
	(error) => {
		console.log(error);
		return Promise.reject(error);
	}
);
//返回一个Promise(发送post请求)
export function fetchPost(url, params) {
	return new Promise((resolve, reject) => {
		axios
			.post(url, params)
			.then(
				(response) => {
					resolve(response);
				},
				(err) => {
					reject(err);
				}
			)
			.catch((error) => {
				reject(error);
			});
	});
}

//返回一个Promise(发送get请求)
export function fetchGet(url, params) {
	var config;
	config = { params: params };
	return new Promise((resolve, reject) => {
		axios
			.get(url, config)
			.then(
				(response) => {
					resolve(response);
				},
				(err) => {
					reject(err);
				}
			)
			.catch((error) => {
				reject(error);
			});
	});
}

// 更新操作
export function fetchPut(url, params) {
	let config;
	config = {};
	return new Promise((resolve, reject) => {
		axios
			.put(url, params, config)
			.then(
				(response) => {
					resolve(response);
				},
				(err) => {
					reject(err);
				}
			)
			.catch((error) => {
				reject(error);
			});
	});
}

//删除操作
export function fetchDelete(url, params) {
	var config;
	config = { params: params };
	return new Promise((resolve, reject) => {
		axios
			.delete(url, config)
			.then(
				(response) => {
					resolve(response);
				},
				(err) => {
					reject(err);
				}
			)
			.catch((error) => {
				reject(error);
			});
	});
}

export default {
	fetchPost,
	fetchGet,
	fetchPut,
	fetchDelete
};
